.Page {
    margin: 0 7rem;   
    margin-top: 8rem;
}

.Page .PageHeader {
    font-family: 'gilroy-heavy';
    font-size: 4.3rem;
    margin: 0;
}

.SectionCard {
    margin: 3rem 0;
}

.SectionCard .Header {
    font-family: 'gilroy-bold';
    font-size: 1.5rem;
    margin: 0;
}

.SectionCard .TextPara {
    font-family: 'gilroy-medium';
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
}