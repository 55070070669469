.SectionTag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  align-items: center;
  font-family: 'gilroy-medium';
  letter-spacing: 0.2rem;
}

.SectionTag div:first-child {
  width: 2.5rem;
  height: 0.4rem;
  margin-right: 0.5rem;
  background-color: white;
  margin-top: 0.1rem;
}