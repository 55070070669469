.Home {
  position: relative;
}

.ProductsSlider, .SuppliersSlider {
  margin: 2.5rem 0;
  margin-bottom: 4rem;
}

.SupllierSlideImage {
  height: auto !important;
  width: auto !important;
}

.TextHead {
  font-family: 'gilroy-heavy';
  font-size: 2.6rem;
  text-align: center;
  margin: auto;
}

.LocationSection {
  margin: 5rem;
}

.LocationSection .SubHead {
  font-family: 'gilroy-heavy';
  font-size: 2rem;
  text-align: center;
  margin: 1.1rem 0;
}

.LocationSection .LocationTags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: auto;
  font-family: 'gilroy-medium';
}

 .LocationSection .LocationTags span {
  padding: 0.2rem 0.6rem;
  margin: 0.3rem 0.6rem;
}

.LocationSection .LocationImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1.2rem 0;
  width: 100%;
}

.LocationImage img {
  width: 100%;
}

.MissionSection, .ContactSection {
  margin-bottom: 5rem;
}

.MissionSection {
  padding: 12rem 0;
  margin-bottom: 5rem;
}

.ContactSection {
  padding: 10rem 0;
  margin-bottom: 5rem;
}


/* MEDIA QUERIES */

@media(max-width: 450px) {
  .LocationSection {
    margin: 1rem;
  }

  .LocationSection .SubHead {
    font-size: 2rem;
    margin: 1.1rem 0;
  }

  .LocationSection .LocationTags {
    width: 100%;
    margin: auto;
  }

  .MissionSection {
    padding: 6rem 0;
  }


  .ContactSection {
    padding: 5rem 0;
  }

}