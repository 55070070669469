.About {
  position: relative;
}

.HeroBanner {
    height: 100vh;
    padding: 0 0;
}

.HeroBanner h2 {
    font-size: 4.5rem;
}

.Stats, .History {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Stats {
    margin-top: 5rem;
}

.Stats img {
    width: 60%;
}

.History {
    flex-direction: column;
    justify-content: center;  
    margin-bottom: 5rem;
}

.History h2 {
    font-family: 'gilroy-bold';
    font-size: 2rem;
    text-align: center;
}

.History img {
    width: 100%;
}

.OurVision {
    margin: 5rem 0;
    margin-top: 10rem;
    width: 100%;
    text-align: center;
}

.OurVision h2 {
    font-family: 'gilroy-bold';
    font-size: 2rem;
    margin: 0;
    margin-bottom: 3rem;
}

.OurVision p {
    font-family: 'gilroy-medium';
    font-size: 1rem;
}

.VisionCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.VisionCards .VisionCard {
    width: 29%;
    margin: 0 2%;
}

.VisionCards .VisionCard p:first-child {
    font-family: 'gilroy-bold';
    font-size: 1.1rem;
}

.VisionCards .VisionCard p:last-child {
    font-family: 'gilroy-medium';
    font-size: 1.1rem;
}

.HeroBanner > p {
    width: 70% !important;
    min-width: 300px;
}



@media(max-width: 650px) {
    .HeroBanner {
        height: 300px;
    }

    .Stats img {
        width: 80%;
    }

    .History img {
        height: 60px;
    }

    .HeroBanner h2 {
        font-size: 7ch;
    }

    .OurVision {
        margin-top: 5rem;
    }

    .OurVision > p {
        width: 95%;
        margin: auto;
    }

    .VisionCards {
        width: 96%;
    }
    
    .VisionCards .VisionCard {
        width: 39%;
        margin: 0 2%;
    }

}