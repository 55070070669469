.Button {
    position: relative;
    font-family: 'gilroy-bold';
    font-size: 1.5rem;
    padding: 0.4rem 1.1rem;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 0 3px;
}

.Button::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  z-index: -1;
  background: linear-gradient(to right, #C83849, #00A0E4, #53AF3B, #DF6C32, #2968B0);
  border-radius: 10px;
  background-clip: padding-box;
}