.PaginationButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PaginationButtonGroup div {
  border: 2px solid black;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  margin: 0.8rem 0.8rem;
  cursor: pointer;
  margin-bottom: 2rem;
}

.PaginationButtonGroup div svg {
  font-size: 1.5rem;
}

.PaginationButtonActive {
  opacity: 1;
}

.SliderImage {
  height: 260px;
  width: 220px;
}


.SliderSection {
    text-align: center;
}

.SliderSection h2 {
    margin-top: 5rem;
    font-size: 2.2rem;
    font-family: 'gilroy-bold';
}

