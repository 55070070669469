@import "./assets/fonts/fonts.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 16px;
  --primary-text-color: black;
  --line-height: 1.7rem;
}

a {
    text-decoration: none;
    color: var(--primary-text-color);
}


@media only screen and (max-width: 1150px) {
  
  :root {
    font-size: 14px;
  }

}


@media only screen and (max-width: 850px) {
  
  :root {
    font-size: 12px;
  }

}


@media only screen and (max-width: 600px) {
  
  :root {
    font-size: 10px;
  }

}