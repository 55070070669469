.Slider {
    height: 100vh;
    max-height: 900px;
}

.SliderImage {
  height: 100%;
  width: 100%;
  object-position: center;
}

.PaginationButton {
  width: 2.8rem;
  height: 0.3rem;
  background: #FFFFFF;
  border-radius: 0;
  opacity: 0.5;
  display: inline-block;
  margin: 0.8rem;
  cursor: pointer;
}

.PaginationButtonActive {
  opacity: 1;
}


.ImageContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
}

.ImageContent img {
  height: 20vh;
  width: 20vh;
}


.ImageContent h2 {
  font-family: 'gilroy-heavy';
  font-size: 5rem;
  color: #FFFFFF;
  margin: 0;
}

.ImageContent p {
  font-family: 'gilroy-medium';
  color: #FFFFFF;
  font-size: 1rem;
  margin: auto;
  margin-bottom: 2rem;
  line-height: 1.5rem;
  width: 65%;
  min-width: 300px;
}


@media(max-width: 850px) {

  .ImageContent p {
    font-family: 'gilroy-medium';
    color: #FFFFFF;
    font-size: 1rem;
    margin: auto;
    margin-bottom: 2rem;
    line-height: 1.5rem;
    width: 80%;
    min-width: 280px;
  }

   .Slider {
      height: 360px;
      width: 100%;
   }

   .SliderImageWrapper {
      height: 100%;
   }

   .SliderImage {
      height: 100%;
    }

    .SliderImage .LogoImage {
      height: 100%;
    }

    .ImageContent .LogoImage {
      height: 3vh;
      width: 3vh;
    }

   .ImageContent h2 {
      font-size: 3rem;
    }

   .ImageContent {
      width: 90%;
    }

}


@media(max-width: 450px) {
   
    .ImageContent .LogoImage {
      display: none;
    }

}