.Section {
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.Section .BackgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.Section .BackgroundImage img {
    height: 100%;
    width: 100%;
}

.TextHead {
  font-family: 'gilroy-heavy';
  font-size: 2.6rem;
  text-align: center;
  margin: 0 auto;
}

.Section .TextHead {
  color: white; 
  width: 20ch;
}

.Section .TextPara {
  color: white; 
  font-family: 'gilroy-medium';
  font-size: 1rem;
  width: 58ch;
  text-align: center;
  margin: 1rem auto;
  line-height: 1.5rem;
}

.Section .ButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}


@media only screen and (max-width: 450px) {

  .Section .TextHead {
    width: 92%;
  }

  .Section .TextPara {
    width: 95%;
  }

}