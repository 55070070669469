.Footer {
    margin: 0 5rem;
    margin-bottom: 3.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 2px solid #F5F5F5;
    padding-top: 3rem;
}

.LeftSection {
    width: 30%;
}

.LogoWrapper {
    height: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.LogoWrapper img {
    width: auto;
}

.CompanyInfo, .CopyrightInfo {
    font-family: 'gilroy-medium';
    font-size: 1rem;
    line-height: 1.5rem;
    color: #878787;
}

.CopyrightInfo {
    width: 30ch;
}

.CopyrightInfo a {
    font-family: 'gilroy-bold';
    text-decoration: underline;
    color: #2968B0;
}

.RightSection .UsefulLinksHeader {
   font-family: 'gilroy-bold';
   font-size: 1.4rem; 
}

.RightSection .PageLink {
    display: flex;
    flex-direction: column; 
}

.RightSection .PageLink a {
    font-family: 'gilroy-medium';
    font-size: 1rem;
    line-height: 1.5rem;
    color: #878787;
    margin-bottom: 0.5rem;
}


@media(max-width: 450px) {
    .Footer {
        margin: 0 2rem;
        flex-direction: column;
        padding-top: 3rem;
    }

    .LeftSection {
        width: 100%;
    }

}