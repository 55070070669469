.NavigationTabsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    backdrop-filter: blur(210px);
    border-radius: 26px;
    height: 4.5rem;
    width: 86%;
    padding: 0 2rem;
    margin-top: 1.5rem;
    position: fixed;
    background: rgba(255, 255, 255, 0.3);
}

.ProductsPageNav {
    background-color: #F3F3F3;
}

.NavigationTabs {
    min-width: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 2rem;
    transition: all 2s ease-out;
}

.NavTab {
    font-family: 'gilroy-medium';
    font-size: 1.1rem;
    line-height: 21px;
    position: relative;
}

.PrimaryNavTab {
    font-family: 'gilroy-bold';
    text-decoration: underline;
}

.DropdownIcon {
    margin-left: 0.5rem;
    cursor: pointer;
}

.Dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    background-color: white;
    font-size: 0.85rem;
    padding: 1.4rem;
    border-radius: 13px;
    border: 1px solid;
    top: 105%;
}

.Dropdown div {
    display: flex;
    flex-direction: column;
}

.Dropdown div:nth-child(even) {
    margin: 0 4rem;
}

.FirstLevelDDName {
    font-family: 'gilroy-bold';
}

.SecondLevelDDName {
    margin-top: 0.7rem;
    font-family: 'gilroy-medium';
    color: #878787;
}

.CtaButton {
    margin-left: auto;
}


.Hamburger {
  width: 3rem;
  height: 2rem;
  position: relative;
  margin: auto;
  margin-right: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: none;
}

.Hamburger span {
  display: block;
  position: absolute;
  height: 0.4rem;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.Hamburger span:nth-child(1) {
  top: 0px;
}

.Hamburger span:nth-child(2) {
  top: 1rem;
}

.Hamburger span:nth-child(3) {
  top: 2rem;
}

.Hamburger.Open span:nth-child(1) {
  top: 1rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.Hamburger.Open span:nth-child(2) {
  opacity: 0;
  left: -3.5rem;
}

.Hamburger.Open span:nth-child(3) {
  top: 1rem;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}



/* MEDIA QUERIES */

@media only screen and (max-width: 850px) {
    .NavigationTabsWrapper {
        width: 88%;
        margin-top: 1.5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .NavigationTabs {
        flex-direction: column;
        justify-content: start;
        position: absolute;
        background-color: white;
        top: 50px;
        width: 100%;
        min-width: unset;
        left: 0;
        margin-left: 0;
        height: calc(100vh - 1.5rem - 60px);
        border: none;
        border-radius: 5px;
        animation-name: menu-animation;
        animation-duration: 0.5s;
        box-shadow: -1px 11px 20px -20px rgba(255,255,255,1),
    -9px 0px 49px -140px rgba(255,255,255,1),
    4px 82px 0px 82px rgba(255,255,255,1);
        overflow-y: scroll;
    }

    @keyframes menu-animation {
        from {height: 0;}
        to {height: calc(100vh - 1.5rem - 60px);}
    }
    

    .NavigationTabs > div {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2rem 0;
    }

    .NavigationTabs > div:first-child {
        margin-top: 6rem;
    }

    .NavigationTabs > div a {
        font-size: 2rem;
    }

    .NavigationTabs > div:has(.PrimaryNavTab) {
        padding: 1.5rem 0;
        width: 95%;
        border-radius: 0.5rem;
    }


    .NavigationTabs > div:has(.PrimaryNavTab) > a {
        text-decoration: none;
    }

    .NavigationTabs .NavTab:active, 
    .NavigationTabs .NavTab:focus {
         -webkit-tap-highlight-color: transparent;
    }

    .CtaButton {
        display: none;
    }

    .SmallScreenCtaButton {
        background: linear-gradient(to right, #C83849, #00A0E4, #53AF3B, #DF6C32, #2968B0);
        width: 80% !important;
        padding: 0.3rem;
        border-radius: 1rem;
        margin-top: auto !important;
    }

    .Hamburger {
        display: block;
    }

    
    .Dropdown {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        background-color: white;
        font-size: 0.85rem;
        padding: 1.4rem 0.5rem;
        border-radius: 13px;
        border: 1px solid #cccccc96;
        top: 1.5rem;
        width: 95%;
        margin: auto;
        animation: dropdown-animation;
        animation-duration: 0.31s;
        flex-wrap: wrap !important;
    }

    @keyframes dropdown-animation {
        from {opacity: 0;}
        to {opacity: 1;}
    }


    .Dropdown div{
        width: 33%;
    }

    .Dropdown div a {
        font-size: 1.2rem;
    }

    .Dropdown div:nth-child(even) {
        margin: 0 0rem;
    }

    
}