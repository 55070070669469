
.ProductsPageWrapper {
    width: 90%;
    margin: auto;
    margin-top: 8rem;
    margin-bottom: 4rem;
}

.PageHeader {
    font-family: 'gilroy-heavy';
    font-size: 4.3rem;
    margin: 0;
}

.PageSubHeader {
    font-family: 'gilroy-bold';
    font-size: 2.2rem;
    color: #909090;
    margin: 0;
    margin-top: 0.5rem;
}

.TabsWrapper {
    margin-top: 2rem;
    width: 100%;
}

.Tabs {
    width: 100%;
    border-bottom: 2px solid #e8e8e8;
    margin-bottom: 1rem;
}

.Tabs button {
    margin-right: 3rem;
    color: #909090;
    font-family: 'gilroy-bold';
    font-size: 0.9rem;
    padding-left: 0;
}

:global(.Mui-selected) {
    color: #000000 !important;
    z-index: -2;
}

:global(.Mui-selected::after) {
    content: '';
    display: block;
    height: 3px;
    width: 50%;
    background-color: #000000;
    position: absolute;
    bottom: -1px;
    z-index: 100;
}

.ProductAndCategoryWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.ProductAndCategoryWrapper .ProductsSection {
    width: 60%;
}

.ProductTags {
    display: flex;
    flex-direction: row;
}

.ProductTags div {
    background-color: #F5F5F5;
    font-size: 0.8rem;
    font-family: 'gilroy-medium';
    padding: 0.8rem 1rem;
    border-radius: 5px;
    margin-right: 2rem;
    cursor: pointer;
    border: 1px solid transparent;
    color: #6e6e6e;
}

.ProductTags div.Selected {
    border: 1px solid black;
    color: black;
    font-family: 'gilroy-bold';
}


.ProductListingCard {
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    padding: 1.2rem;
    border-radius: 0.8rem;
    margin-top: 1rem;
    cursor: pointer;
    height: 12rem;
}

.ProductCardContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 1.5rem;
}

.ProductListingCard .ProductCardImage img {
    width: 12rem;
    height: 100%;
}

.ProductCardContent .ProductText {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 60%;
}

.ProductCardContent .ProductText span:first-child {
    font-family: 'gilroy-medium';
    font-size: 0.8rem;
}


.ProductCardContent .ProductText span:last-child {
    font-family: 'gilroy-bold';
    font-size: 1.5rem;
}

.ActionBtnWrapper .ActionBtn {
    background-color: #D9D9D9;
    padding: 0.8rem;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.ActionBtnWrapper .ActionBtn span {
    margin-left: 0.4rem;
    font-family: 'gilroy-medium';
    font-size: 1rem;
}


/* CATEGORY SECTION */
.CategorySection {
    width: 35%;
}

.CategorySection .Header, 
.CategorySection .SubHeader {
    margin: 0;
    font-family: 'gilroy-bold';
    font-size: 1.2rem;
}

.CategorySection .SubHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CategorySection .SubHeader p {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CategorySection .SubHeader p:last-child span:last-child {
    margin-left: 0.7rem;
    cursor: pointer;
}

.CategorySection .SubCategories {
    color: #878787;
    font-size: 0.8rem;
    font-family: 'gilroy-medium';
}

.CategorySection .SubCategories p {
    cursor: pointer;
}




@media(max-width: 950px) {

    .ProductCardContent {
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
    }
    
    .ProductCardContent .ProductText {
        width: 90%;
        flex-direction: column;
        margin-bottom: 1rem;
    }

}

@media(max-width: 650px) {
    .ActionBtnWrapper .ActionBtn {
        padding: 0.5rem;
    }

    .ActionBtn img {
        height: 1.5rem;
    }

    .ProductTags div {
        margin-right: 1rem;
    }
}

@media(max-width: 450px) {
    .ProductAndCategoryWrapper {
        flex-direction: column;
    }

    .ProductAndCategoryWrapper .ProductsSection {
        width: 100%;
    }

    .CategorySection {
        width: 100%;
        margin-top: 3rem;
    }

    .CategorySection .Category {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    .CategorySection .Category > div {
        width: 45%;
    }

}