.ProductDetailWrapper {
    width: 90%;
    margin: auto;
    margin-top: 10rem;
    margin-bottom: 4rem;
}

.Content { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ProductDetail {
    width: 54%;
    min-width: 600px;
    padding: 1rem;
}

.ProductDetail .TopInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 20rem;
    margin-bottom: 3.8rem;
}

.ProductDetail .TopInfo .ProductImageWrapper {
    width: 42%;
    border-radius: 10px;
}

.ProductImageWrapper img {
    width: 100%;
    height: 100%;
}


.ProductDetail .TopInfo .Details {
    width: 50%;
    height: 100%;
    position: relative;
    margin-left: 2.4rem;
}

.ProductDetail .TopInfo .Details .Header {
    font-family: 'gilroy-bold';
    font-size: 2.1rem;
    margin-bottom: 1rem;
    margin-top: 0;
}

.ProductDetail .TopInfo .Details .SubHeader {
    font-family: 'gilroy-medium';
    font-size: 1.3rem;
    margin: 0.4rem 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.ProductDetail .TopInfo .Details .Tag {
    margin: 0.8rem 0;
    font-family: 'gilroy-medium';
    font-size: 1rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
}


.ProductDetail .TopInfo .Details .Tag .Key {
    color: #888888;
}

.ProductDetail .TopInfo .Details .Tag .Value {
    color: black;
    margin-left: 0.2rem;
}

.ProductDetail .TopInfo .ChatNowBtnWrapper {
    width: 100%;
    background-color: #2968B0;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}

.ProductDetail .TopInfo .ChatNowBtnWrapper .ChatNowBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.6rem 0;
    color: #FFFFFF;
    align-items: center;
}

.ProductDetail .TopInfo .ChatNowBtnWrapper .ChatNowBtn span {
    margin-left: 0.5rem;
    font-family: 'gilroy-bold';
}

.InfoTable {
    margin: 2rem 0;
    margin-bottom: 4em;

}

.InfoTable table th {
    font-family: 'gilroy-bold';
}

.InfoTable table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.InfoTable td, th {
  border: 1px solid #DDDDDD;
  text-align: left;
  padding: 8px;
}

.InfoTable tr:nth-child(even) {
  background-color: #F5F5F5;
}

.TDS .DocumentWrapper {
    background-color: #F5F5F5;
    border-radius: 0.9rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0.7rem;
    align-items: center;
}

.TDS .DocumentWrapper .Text {
    color: black;
    text-transform: capitalize;
    font-family: 'gilroy-medium';
    font-size: 1rem;
}

.TDS .DocumentWrapper .Buttons {
    display: flex;
    flex-direction: row;
}

.TDS .DocumentWrapper .Button {
    background-color: #2968B0;
    border-radius: 10px;
    padding: 0.7rem 0.6rem;
    color: #FFFFFF;
    align-items: center;
    margin-left: 1rem;
    cursor: pointer;
}



/* BOTTOM INFO */
.BottomInfo .Header {
    font-family: 'gilroy-bold';
    font-size: 1.4rem;
}

.BottomInfo .Text {
    font-family: 'gilroy-medium';
    text-transform: capitalize;
    font-size: 0.9rem;
    color: #939393;
    line-height: 1.5rem;
}


/* CATEGORY SECTION */
.Others {
    width: 28%;
}

.CategorySection {
    width: 100%;
    margin-bottom: 1.8rem;
}

.CategorySection .Header, 
.CategorySection .SubHeader {
    margin: 0;
    font-family: 'gilroy-bold';
    font-size: 1.2rem;
}

.CategorySection .SubHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CategorySection .SubHeader p {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CategorySection .SubHeader p:last-child span:last-child {
    margin-left: 0.7rem;
    cursor: pointer;
}

.CategorySection .SubCategories {
    color: #878787;
    font-size: 0.8rem;
    font-family: 'gilroy-medium';
}

.CategorySection .SubCategories p,
.CategorySection .OtherProducts p {
    cursor: pointer;
}

.ProductImagePrint {
    height: 60% !important;
    width: 60% !important;
    display: block;
    margin: auto;
}


@media(max-width: 920px) {
    .Content { 
        flex-direction: column;
    }

    .ProductDetail {
        width: 100%;
        min-width: 100%;
        padding: 1rem;
    }

    .Others {
        margin-top: 2rem;  
        width: 100%;  
    }

    .CategorySection {
        width: 100%;
        margin-top: 3rem;
    }

    .CategorySection .Category {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    .CategorySection .Category > div {
        width: 45%;
    }

    .PreviewButton {
        display: none;
    }
}