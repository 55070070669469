.Contact {
  position: relative;
  margin-bottom: 3rem;
}

.HeroBanner {
    height: 100vh;
    padding: 0 0;
}

.HeroBanner h2 {
    font-size: 4.5rem;
}

.HeroBanner > p {
    width: 58% !important;
}


.ContactCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3rem 4rem;
    margin-bottom: 6rem;
}

.ContactCard .Card {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #F5F5F5;
    border-radius: 17px;
    padding: 1.4rem 1.8rem;
}

.ContactCard .Card img {
    height: 5rem;
}

.ContactCard .Card h2 {
    font-family: 'gilroy-bold';
    font-size: 1.8rem;
    margin: 0.8rem 0;
}

.ContactCard .Card p {
    font-family: 'gilroy-medium';
    font-size: 0.9rem;
    margin: 0 auto;
    width: 65%;
    line-height: 20px;
}

.ContactCard .Card .Button {
    display: block;
    font-family: 'gilroy-bold';
    background-color: #2968B0;
    font-size: 0.9rem;
    text-align: center;
    padding: 0.8rem 0;
    color: #FFFFFF;
    border-radius: 9px;
    margin-top: 1.2rem;
}

.FormWrapper {
    margin: 3.5rem 4rem;
    background-color: #F5F5F5;
    border-radius: 17px;
    padding: 2.2rem 0;
}

.FormWrapper h2 {
    font-family: 'gilroy-bold';
    font-size: 1.8rem;
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 0;
}

.FormWrapper form {
    width: 78%;
    margin: auto;
}

.FormWrapper form .EqualySpacedInputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.FormWrapper .CustomInputGrp {
    display: flex;
    flex-direction: column;
}


.FormWrapper form .EqualySpacedInputs .CustomInputGrp {
    width: 43%;
}

.FormWrapper .CustomInputGrp label {
    font-family: 'gilroy-bold';
    font-size: 1rem;
    margin-bottom: 0.7rem;
}

.FormWrapper .CustomInputGrp input {
    border: none;
    border-bottom: 1px solid #878787;
    background-color: transparent;
    padding-bottom: 0.6rem;
    padding-left: 0;
}

.FormWrapper .CustomInputGrp input:focus {
    outline: none;
}

.FormWrapper .CustomInputGrp input::placeholder {
    color: #878787;
     font-size: 0.9rem;
}

.FormWrapper .CustomInputGrp p {
    margin: 0;
    font-size: 0.8rem;
    color: red;
    height: 30px;
}

.FormWrapper .SubmitBtn {
    display: block;
    font-family: 'gilroy-bold';
    background-color: #2968B0;
    font-size: 0.9rem;
    text-align: center;
    padding: 0.8rem 0;
    color: #FFFFFF;
    border-radius: 9px;
    border: none;
    width: 60%;
    margin: auto;
    margin-top: 2rem;
    cursor: pointer;
}



@media(max-width: 650px) {
    .HeroBanner {
        height: 300px;
    }

    .ContactCard {
        flex-direction: column;
    }

    .ContactCard .Card {
        width: 88%;
        margin-bottom: 2rem;
    }

    .FormWrapper form .EqualySpacedInputs {
        flex-direction: column;
    }

    .FormWrapper form .EqualySpacedInputs .CustomInputGrp {
        width: 100%;
    }

}