@font-face {
  font-family: "gilroy-heavy";
  src: url("gilroy-heavy.ttf");
}

@font-face {
  font-family: "gilroy-bold";
  src: url("gilroy-bold.ttf");
}

@font-face {
  font-family: "gilroy-medium";
  src: url("gilroy-medium.ttf");
}